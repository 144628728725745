<template>
  <div>
    <div class='mt-10 mb-12'>
      <label class='inline-block mr-6'>사용자 명</label>
      <input type='text' class='form-input' v-model='name' />
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-12'>email</label>
      <input type='text' class='form-input' v-model='drilldownUser.email' disabled />
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-12'>sms #</label>
      <input type='text' class='form-input' v-model='sms_number' @input='filterInvalidChars' />
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-12'>roles</label>
      <v-select v-model='roles' multiple :options='roleOptions'></v-select>
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-12'>{{layerLabel}}</label>
      <select v-model='layer'
              class='form-select'>
        <option value=""></option>
        <option v-for='layer in filteredLayer'
                :key='layer.id'
                :value='layer.field_name'>
          {{layer.field_name}}
        </option>
      </select>
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-12'>{{departmentLabel}}</label>
      <select v-model='department'
              class='form-select'>
        <option value=""></option>
        <option v-for='department in filteredDepartment'
                :key='department.id'
                :value='department.field_name'>
          {{department.field_name}}
        </option>
      </select>
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-16'>{{teamLabel}}</label>
      <select v-model='team'
              class='form-select'>
        <option value=""></option>
        <option v-for='team in filteredTeam'
                :key='team.id'
                :value='team.field_name'>
          {{team.field_name}}
        </option>
      </select>
    </div>
    <div class='mb-12'>
      <label class='inline-block mr-12'>{{titleLabel}}</label>
      <select v-model='title'
              class='form-select'>
        <option value=""></option>
        <option v-for='title in filteredTitle'
                :key='title.id'
                :value='title.field_name'>
          {{title.field_name}}
        </option>
      </select>
    </div>
    <button
      class='mr-4 inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md'
      style='background-color: #223645;'
      @click='submitUser'>
      저장
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'UserDetails',
  computed: {
    ...mapFields('users', {
      drilldownUser: 'drilldownUser',
      name: 'drilldownUser.name',
      sms_number: 'drilldownUser.sms_number',
      roles: 'drilldownUser.roles',
      department: 'drilldownUser.organogram.department',
      team: 'drilldownUser.organogram.team',
      title: 'drilldownUser.organogram.title',
      layer: 'drilldownUser.organogram.layer',
    }),
    ...mapState('codeLists', [
      'codeLists',
    ]),
    roleOptions () {
      return ['manager', 'TeamLead']
    },
    departments () {
      return this.codeLists.department ? this.codeLists.department : []
    },
    teams () {
      return this.codeLists.team ? this.codeLists.team : []
    },
    titles () {
      return this.codeLists.title ? this.codeLists.title : []
    },
    layers () {
      return this.codeLists.layer ? this.codeLists.layer : []
    },
    departmentLabel () {
      let found = this.departments.find(ret => ret.is_header)
      return found ? found.field_name : '부서'
    },
    teamLabel () {
      let found = this.teams.find(ret => ret.is_header)
      return found ? found.field_name : '팀'
    },
    titleLabel () {
      let found = this.titles.find(ret => ret.is_header)
      return found ? found.field_name : '직급'
    },
    layerLabel () {
      let found = this.layers.find(ret => ret.is_header)
      return found ? found.field_name : '조회권한'
    },
    filteredDepartment () {
      return this.departments.filter(ret => ret.is_header === false)
    },
    filteredTeam () {
      return this.teams.filter(ret => ret.is_header === false)
    },
    filteredTitle () {
      return this.titles.filter(ret => ret.is_header === false)
    },
    filteredLayer () {
      return this.layers.filter(ret => ret.is_header === false)
    },
  },
  methods: {
    ...mapActions('users', [
      'updateUser',
    ]),
    ...mapActions('codeLists', [
      'getCodeLists',
    ]),
    filterInvalidChars () {
      this.sms_number = this.sms_number.replace(/\D/g, '')
    },
    submitUser () {
      this.updateUser(this.drilldownUser)
    },
  },
  mounted () {
    this.getCodeLists()
  }
}
</script>
